'use client';

import ImageAssets from '@/data/ImageAssets';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import UTMCampaignBuilderUtils from '@/utils/UTMCampaignBuilderUtils';
import CldImageClient from "@/app-components/page/common/CldImageClient";
import {useLocale} from 'next-intl';
import {Link, usePathname, useRouter} from "@/i18n/routing";

const darkPages: string[] = [
	'^/$',
	'^/about-us/.+',
	'^/blog$',
	'^/blog/*',
	'^/contact$',
	'^/demo$',
	'^/expert/.+',
	'^/experts$',
	'^/newsletter$',
	'^/pricing$',
];

function stickyHeader() {
	if (window.matchMedia('(min-width: 992px)').matches) {
		const stickyNav = document.querySelector('.header-sticky') as HTMLElement;
		document.addEventListener('scroll', function () {
			const scTop = window.scrollY || document.documentElement.scrollTop;
			if (scTop >= 400) {
				stickyNav?.classList.add('header-sticky-on');
			} else {
				stickyNav?.classList.remove('header-sticky-on');
			}
		});
	}
}
function RenderResourcesDropdown() {
	const locale = useLocale();

	const tHeadFoot = useTranslations('hf.resources');
	const t = useTranslations('navigation');

	return (
		<li className="nav-item dropdown">
			<a
				className="nav-link dropdown-toggle"
				href="#"
				data-bs-toggle="dropdown"
				data-bs-auto-close="true"
				aria-haspopup="true"
				aria-expanded="false"
			>
				{t('resources')}
			</a>
			<div className="dropdown-menu dropdown-menu-center dropdown-menu-size-xl p-3">
				<div className="row g-xl-3">
					{/* Work */}
					<div className="col-xl-8 d-none d-xl-block">
						<div className="d-flex gap-4">
							<div className="card bg-transparent">
								<CldImageClient
									className="card-img border"
									src="Medentic.app/pages/resources/bg-dental-procedure-codes-nzis-header-thumb_hotmlm.png"
									alt={tHeadFoot('bg-dental-procedure-codes.title')}
									width={240}
									height={180}
									crop="fit"
								/>

								<div className="card-body px-0 text-start pb-0">
									<Link
										className="h6"
										href={'/resources/bg-dental-procedure-codes'}
									>
										{tHeadFoot('bg-dental-procedure-codes.title')}
									</Link>
									<p className="mb-2 small">
										{tHeadFoot('bg-dental-procedure-codes.description')}
									</p>

									<Link
										className="icon-link icon-link-hover stretched-link mb-0"
										href={'/resources/bg-dental-procedure-codes'}
									>
										{tHeadFoot('bg-dental-procedure-codes.cta')}
										<i className="bi bi-arrow-right"/>
									</Link>
								</div>
							</div>


							<div className="card bg-transparent">
								<CldImageClient
									className="card-img border"
									src="Medentic.app/pages/resources/bg-dental-procedure-codes-nzis-header-thumb_hotmlm.png"
									alt={tHeadFoot('bg-dental-procedure-codes.title')}
									width={240}
									height={180}
									crop="fit"
								/>

								<div className="card-body px-0 text-start pb-0">
									<Link
										className="h6"
										href={'/resources/bg-dental-procedure-codes'}
									>
										{tHeadFoot('bg-dental-procedure-codes.title')}
									</Link>
									<p className="mb-2 small">
										{tHeadFoot('bg-dental-procedure-codes.description')}
									</p>

									<Link
										className="icon-link icon-link-hover stretched-link mb-0"
										href={'/resources/bg-dental-procedure-codes'}
									>
										{tHeadFoot('bg-dental-procedure-codes.cta')}
										<i className="bi bi-arrow-right"/>
									</Link>
								</div>
							</div>

							<div className="vr ms-2"/>
						</div>
					</div>
					<div className="col-xl-8 d-block d-xl-none">
						<ul className="list-unstyled">
							<li>
								<Link className="dropdown-item heading-color" href={'/resources/bg-dental-procedure-codes'}>
									{tHeadFoot('bg-dental-procedure-codes.title')}
								</Link>
							</li>
						</ul>
					</div>
					{/* Index nav links */}
					<div className="col-xl-4">
						<ul className="list-unstyled">
							<li>
								<Link className="dropdown-item heading-color" href={'/resources'}>
									{tHeadFoot('all_resources')}
								</Link>
							</li>
							<li>
								<Link className="dropdown-item heading-color" href={'/promo'}>
									{t('promo')}
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12">
					<hr className="mt-2"/>
					<div className="align-items-center">
						<ul className="list-inline px-2">
							<li className="list-inline-item">
								<span className="fw-bold">
									{tHeadFoot('need_help')}
								</span>
							</li>
							<li className="list-inline-item">
								<Link className="dropdown-item heading-color" href={'https://support.medentic.app/' + locale} target="_blank">
									{t('help_center')}
								</Link>
							</li>
							<li className="list-inline-item">
								<Link className="dropdown-item heading-color" href="/contact" >
									{t('contact_us')}
								</Link>
							</li>
							<li className="list-inline-item">
								<Link className="dropdown-item heading-color" href="/changelog">
									{t('latest_releases')}
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</li>
	);
}

function Header() {
	const locale = useLocale();
	useEffect(stickyHeader, []);
	const pathname = usePathname();
	const router = useRouter();
	const t = useTranslations('navigation');

	const navbarTheme = darkPages.some((path) => pathname.match(path)) ? 'dark' : 'light';

    const [isMenuOpen, setIsMenuOpen] = useState(false);
	useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    const toggleMenu = (event: React.MouseEvent) => {
        setIsMenuOpen((prevState) => !prevState);
    };


	const LANGUAGES = [
		{
			locale: 'en',
			img: ImageAssets.UKFlag,
			label: 'English',
		},
		{
			locale: 'bg',
			img: ImageAssets.BGFlag,
			label: 'Български',
		},
	];

	return (
		<header className="header-absolute header-sticky" data-bs-theme={navbarTheme} style={{zIndex: 2147483000 + 3}}>
			<nav className="navbar navbar-expand-xl">
				<div className="container">
					<Link className="navbar-brand me-0" href={'/'}>
						<Image
							className={`${navbarTheme}-mode-item`}
							src={navbarTheme === 'light' ? ImageAssets.DarkLogo : ImageAssets.LightLogo}
							alt={t('company') + ' logo'}
							width={120}
							height={30}
						/>
					</Link>

					{/* Main navbar START */}
					<div className={`d-print-none navbar-collapse collapse ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
						<ul className="navbar-nav navbar-nav-scroll dropdown-hover mx-auto">
							<li className="nav-item">
								<Link className="nav-link" href={'/pricing'}>
									{t('pricing')}
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" href={'/blog'}>
									{t('blog')}
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" href={'/events'}>
									{t('events')}
								</Link>
							</li>
							{/* Use the React component here */}
							<RenderResourcesDropdown />
						</ul>
					</div>
					{/* Main navbar END */}

					{/* Buttons */}
					<ul className="nav align-items-center dropdown-hover ms-sm-2 d-print-none">
						{/* Dark mode option */}
						<li className="nav-item dropdown dropdown-animation">
							<button className="btn mb-0 px-2 btn-link" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static">
								<i className="bi bi-globe text-gray-900 fa-fw"></i>
							</button>
							<ul className="dropdown-menu min-w-auto dropdown-menu-end" aria-labelledby="bd-theme" style={{ width: '150px' }}>
								{LANGUAGES.map((item) => (
									<li className="mb-1" key={item.locale}>
										<button
											type="button"
											className={`dropdown-item d-flex align-items-center ${item.locale == locale ? 'active' : ''}`}
											onClick={() =>
												router.replace(pathname, {locale: item.locale})
											}
										>
											<Image className="fa-fw me-2" src={item.img} height="13" width="30" alt="language flag" />
											{item.label}
										</button>
									</li>
								))}
							</ul>
						</li>
						{/* Buttons start */}
						<li className="nav-item d-none d-sm-block">
							<Link href={UTMCampaignBuilderUtils.generate(process.env.NEXT_PUBLIC_APP_URL + '/v/register', 'www.medentica.app', 'website', 'website-header', 'free-registration')} className="btn btn-sm btn-dark mb-0" target="_blank">
								{t('register')}
							</Link>
						</li>
						<li className="nav-item me-2">
							<Link href={UTMCampaignBuilderUtils.generate(process.env.NEXT_PUBLIC_APP_URL + '/v/login', 'www.medentica.app', 'website', 'website-header', 'free-registration')} target="_blank" className="nav-link mb-0">
								{t('login')}
							</Link>
						</li>

						<li className="nav-item">
							<button
								className="navbar-toggler"
								type="button"
								aria-controls="navbarCollapse"
								aria-expanded={isMenuOpen}
								aria-label="Toggle navigation"
								onClick={toggleMenu}
								>
								<span className="navbar-toggler-animation">
									<span />
									<span />
									<span />
								</span>
							</button>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
}

export default Header;
